import Layout from '@/common/components/Layout';
import { LanguageContext } from '@/common/context/LanguageContext';
import { bbcodeToHtml } from '@/common/lib/bbcode';
import {
  BodyText,
  Heading3,
  Heading6,
} from '@/modules/design/components/textstyles';
import { FC, useCallback, useContext, useState } from 'react';
import styled from 'styled-components';

const FAQ_ITEMS: [string, string][] = [
  ['closure_faq_1_question', 'closure_faq_1_answer'],
  ['closure_faq_2_question', 'closure_faq_2_answer'],
  ['closure_faq_3_question', 'closure_faq_3_answer'],
];

const Container = styled.main`
  max-width: 720px;
  margin: 0 auto;
  padding: 0 20px 128px 20px;
`;

const StyledBodyText = styled(BodyText)`
  white-space: pre-wrap;
`;

const MarginBodyText = styled(StyledBodyText)`
  margin-top: 24px;
`;

const ClosedIcon = styled.img.attrs({
  src: require('@/modules/assets/icons/closed.svg'),
})`
  width: 127px;
  height: 110px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: horizontal;
  justify-content: space-between;
  cursor: pointer;
  padding: 16px 4px;
`;

const Title = styled(Heading3)`
  margin-top: 40px;
`;

const FaqContainer = styled.div`
  margin-top: 16px;
`;

const AccordionContainer = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.themeColors.border};
`;

interface ChevronProps {
  isExpanded: boolean;
}

const Chevron = styled.img.attrs({
  src: require('@/modules/assets/icons/angle-down.svg'),
})<ChevronProps>`
  transform: ${({ isExpanded }) => (isExpanded ? 'rotate(180deg)' : 'none')};
`;

interface ContentContainerProps {
  isExpanded: boolean;
}

const ContentContainer = styled.div<ContentContainerProps>`
  display: ${({ isExpanded }) => (isExpanded ? 'block' : 'none')};
  padding: 8px 4px 24px 4px;
`;

const AccordionItem: FC<{ titleKey: string; contentKey: string }> = ({
  titleKey,
  contentKey,
}) => {
  const { translate } = useContext(LanguageContext);
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleIsExpanded = useCallback(
    () => setIsExpanded((isExpanded) => !isExpanded),
    [setIsExpanded]
  );

  return (
    <AccordionContainer>
      <TitleContainer onClick={toggleIsExpanded}>
        <Heading6>{translate(titleKey)}</Heading6>
        <Chevron isExpanded={isExpanded} />
      </TitleContainer>
      <ContentContainer isExpanded={isExpanded}>
        <StyledBodyText
          dangerouslySetInnerHTML={{
            __html: bbcodeToHtml(translate(contentKey)),
          }}
        />
      </ContentContainer>
    </AccordionContainer>
  );
};

const ClosedPage: FC = () => {
  const { translate } = useContext(LanguageContext);

  return (
    <Layout>
      <Container>
        <ClosedIcon />
        <Title>{translate('closed_landing_title')}</Title>
        <MarginBodyText>{translate('closed_landing_expl')}</MarginBodyText>
        <Title>{translate('help_faq')}</Title>
        <FaqContainer>
          {FAQ_ITEMS.map(([titleKey, contentKey]) => (
            <AccordionItem
              key={titleKey}
              titleKey={titleKey}
              contentKey={contentKey}
            />
          ))}
        </FaqContainer>
        <Title>{translate('closure_contact_title')}</Title>
        <MarginBodyText
          dangerouslySetInnerHTML={{
            __html: bbcodeToHtml(translate('closed_content_expl')),
          }}
        />
      </Container>
    </Layout>
  );
};

export default ClosedPage;
