import Footer from '@/common/components/Footer/Footer';
import AlternateLanguages from '@/common/components/HtmlHead/AlternateLanguages';
import AppLinks from '@/common/components/HtmlHead/AppLinks';
import { DefaultMetaData } from '@/common/components/HtmlHead/MetaData';
import Layout from '@/common/components/Layout';
import ResponsiveContainer from '@/common/components/ResponsiveContainer';
import { ExperimentContext } from '@/common/context/ExperimentContext';
import { LanguageContext } from '@/common/context/LanguageContext';
import ClosedPage from '@/modules/closure/components/ClosedPage';
import AppBar from '@/modules/home/components/AppBar';
import HeaderBar from '@/modules/home/components/HeaderBar';
import PopularItems from '@/modules/home/components/PopularItems';
import SellersBar from '@/modules/home/components/SellersBar';
import { BuyingBar, SellingBar } from '@/modules/home/components/StepsBar';
import { NextPage } from 'next';
import { useContext } from 'react';

const Home: NextPage = () => {
  const { translate } = useContext(LanguageContext);
  const { closure } = useContext(ExperimentContext);

  return (
    <>
      <AlternateLanguages />
      <DefaultMetaData title={translate('home_title')} />
      <AppLinks />
      <Layout>
        {closure.isInVariant ? (
          <ClosedPage />
        ) : (
          <>
            <HeaderBar />
            <ResponsiveContainer>
              <PopularItems />
              <SellingBar />
              <SellersBar />
              <BuyingBar />
              <AppBar />
            </ResponsiveContainer>
            <Footer />
          </>
        )}
      </Layout>
    </>
  );
};

export default Home;
