import Head from 'next/head';
import { FC } from 'react';
import { appName } from '../../constants';

export const MetaData: FC<{
  title?: string;
  ogTitle?: string;
  description?: string;
  ogDescription?: string;
  ogImage?: string;
  noIndex?: boolean;
}> = ({ title, ogTitle, description, ogDescription, ogImage, noIndex }) => (
  <Head>
    {title ? <title>{title}</title> : null}
    {ogTitle ? (
      <meta property="og:title" content={ogTitle} key="og:title" />
    ) : null}
    {description ? (
      <meta name="description" content={description} key="description" />
    ) : null}
    {ogDescription ? (
      <meta
        property="og:description"
        content={ogDescription}
        key="og:description"
      />
    ) : null}
    {ogImage ? (
      <meta property="og:image" content={ogImage} key="og:image" />
    ) : null}
    {noIndex ? <meta name="robots" content="noindex" key="robots" /> : null}
  </Head>
);

export const DefaultMetaData: FC<{
  title: string;
  description?: string;
  image?: string;
  noIndex?: boolean;
  omitTitleSuffix?: boolean;
}> = ({ title, description, image, noIndex, omitTitleSuffix }) => (
  <MetaData
    title={omitTitleSuffix ? title : `${title} | ${appName}`}
    ogTitle={title}
    description={description}
    ogDescription={description}
    ogImage={image}
    noIndex={noIndex}
  />
);
