import { FavoriteEntryPoint } from '@/modules/design/components/buttons/ToggleFavoriteButton';
import AngleDown from '@/modules/design/components/icons/AngleDown';
import { FC, useEffect, useRef, useState } from 'react';
import styled, { DefaultTheme, withTheme } from 'styled-components';
import { ListProductFragment } from '../generated/graphql';
import ProductListItem from './ProductListItem/ProductListItem';

const Container = styled.section`
  position: relative;
  width: 100%;
  overflow: hidden;
`;

const NavigationButton = styled.div<{ visible: boolean }>`
  position: absolute;
  width: 48px;
  height: 48px;
  border-radius: 24px;
  box-shadow: 0 2px 4px 0 rgba(8, 34, 41, 0.2);
  background-color: #ffffff;
  top: 128px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 0.2s ease-in-out;
  pointer-events: ${({ visible }) => (visible ? 'initial' : 'none')};

  svg * {
    fill: ${({ theme }) => theme.colors.CalypsoCP1};
  }

  @media (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.mobileMaxWidth}) {
    display: none;
  }
`;

const PreviousButton = styled(NavigationButton)`
  left: calc(25% - 4px);
  transform: translate(-50%, -50%);

  svg {
    transform: rotate(90deg);
  }
`;

const NextButton = styled(NavigationButton)`
  transform: translate(-50%, -50%);
  left: calc(75% + 4px);

  svg {
    transform: rotate(-90deg);
  }
`;

const ProductsContainer = styled.section`
  position: relative;
  display: grid;
  overflow: scroll;
  grid-template-columns: repeat(4, minmax(100px, 1fr));
  /* margin: 24px 0 64px 0; */
  scroll-snap-type: x mandatory;
  grid-column-gap: 16px;
  width: 100%;

  ::-webkit-scrollbar {
    width: 0 !important;
  }
  -ms-overflow-style: none;

  @media (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.mobileMaxWidth}) {
    grid-template-columns: repeat(2, minmax(100px, 1fr));
  }
`;

const StyledProductListItem = styled(ProductListItem)`
  :nth-child(4n + 1) {
    scroll-snap-align: start;
  }

  :nth-child(5),
  :nth-child(6),
  :nth-child(7),
  :nth-child(8) {
    transform: translate(calc(400% + 4 * 16px), -100%);
  }

  :nth-child(9),
  :nth-child(10),
  :nth-child(11),
  :nth-child(12) {
    transform: translate(calc(800% + 8 * 16px), -200%);
  }

  @media (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.mobileMaxWidth}) {
    scroll-snap-align: start;

    :nth-child(3),
    :nth-child(4) {
      transform: translate(calc(200% + 2 * 16px), -100%);
    }

    :nth-child(5),
    :nth-child(6) {
      transform: translate(calc(400% + 4 * 16px), -200%);
    }

    :nth-child(7),
    :nth-child(8) {
      transform: translate(calc(600% + 6 * 16px), -300%);
    }

    :nth-child(9),
    :nth-child(10) {
      transform: translate(calc(800% + 8 * 16px), -400%);
    }

    :nth-child(11),
    :nth-child(12) {
      transform: translate(calc(1000% + 10 * 16px), -500%);
    }
  }
`;

const IndicatorContainer = styled.div`
  position: absolute;
  bottom: 32px;
  left: 50%;
  transform: translate(-50%, 50%);
  width: 120px;
  height: 6px;
  background-color: #f0f3f5;
  border-radius: 3px;
`;

const Indicator = styled.div`
  width: 33%;
  height: 100%;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.colors.CalypsoCP1};
`;

const HorizontalProductScroller: FC<{
  theme: DefaultTheme;
  products: ListProductFragment[];
  entryPoint: FavoriteEntryPoint;
}> = ({ products, entryPoint, theme }) => {
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(3);
  const containerRef = useRef<HTMLElement>(null);
  const firstProductRef = useRef<HTMLElement>(null);
  const productsContainerRef = useRef<HTMLElement>(null);
  const indicatorRef = useRef<HTMLDivElement>(null);

  // Set height
  useEffect(() => {
    const container = containerRef.current;
    const firstProduct = firstProductRef.current;
    if (!container || !firstProduct) {
      return;
    }
    container.style.height = `${firstProductRef.current.clientHeight + 70}px`;
  }, [containerRef.current, firstProductRef.current, pageCount]);

  // Update indicator
  useEffect(() => {
    const productsContainer = productsContainerRef.current;
    const indicator = indicatorRef.current;
    if (!productsContainer || !indicator) {
      return;
    }

    const invalidateIndicator = () => {
      const percentage =
        100 *
        (productsContainer.scrollLeft /
          (productsContainer.clientWidth * pageCount));
      indicator.style.marginLeft = `${percentage}%`;
      indicator.style.width = `${100 / pageCount}%`;
    };

    productsContainer.onscroll = () => {
      if (
        productsContainer.scrollLeft % (productsContainer.clientWidth + 16) ===
        0
      ) {
        setPage(
          productsContainer.scrollLeft / (productsContainer.clientWidth + 16)
        );
      }
      invalidateIndicator();
    };
    invalidateIndicator();
  }, [productsContainerRef.current, indicatorRef.current, pageCount]);

  // Lister for screen resize
  useEffect(() => {
    let timer: number;

    const handleResize = () => {
      if (timer) {
        clearTimeout(timer);
        timer = null;
      } else {
        const width =
          window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth;
        setPageCount(
          width > parseInt(theme.breakpoints.mobileMaxWidth, 10) ? 3 : 6
        );
      }
      timer = window.setTimeout(() => {
        timer = null;
      }, 100);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const navigatePrev = () => {
    const productsContainer = productsContainerRef.current;
    if (!productsContainer) {
      return;
    }
    productsContainer.scrollTo({
      left: Math.max(
        0,
        productsContainer.scrollLeft - productsContainer.clientWidth - 16
      ),
      behavior: 'smooth',
    });
  };

  const navigateNext = () => {
    const productsContainer = productsContainerRef.current;
    if (!productsContainer) {
      return;
    }

    productsContainerRef.current.scrollTo({
      left: Math.min(
        pageCount * (productsContainer.clientWidth - 1),
        productsContainer.scrollLeft + productsContainer.clientWidth + 16
      ),
      behavior: 'smooth',
    });
  };

  return (
    <Container ref={containerRef}>
      <ProductsContainer ref={productsContainerRef}>
        {products.map((product, i) => (
          <StyledProductListItem
            key={product.id}
            product={product}
            ref={i === 0 ? firstProductRef : undefined}
            entryPoint={entryPoint}
          />
        ))}
      </ProductsContainer>
      <PreviousButton onClick={navigatePrev} visible={page > 0}>
        <AngleDown />
      </PreviousButton>
      <NextButton onClick={navigateNext} visible={page + 1 < pageCount}>
        <AngleDown />
      </NextButton>

      <IndicatorContainer>
        <Indicator ref={indicatorRef} />
      </IndicatorContainer>
    </Container>
  );
};

export default withTheme(HorizontalProductScroller);
