import Head from 'next/head';
import { useRouter } from 'next/router';
import { FC } from 'react';
import { androidPackage, appName, appStoreId } from '../../constants';
import { appUrl } from '../../utils/url';

const AppLinks: FC<{ path?: string }> = ({ path }) => {
  const { asPath } = useRouter();
  const appLink = appUrl(path ?? asPath);

  return (
    <Head>
      <meta
        property="al:android:package"
        key="al:android:package"
        content={androidPackage}
      />
      <meta property="al:android:url" key="al:android:url" content={appLink} />
      <meta
        property="al:android:app_name"
        key="al:android:app_name"
        content={appName}
      />

      <meta property="al:ios:url" key="al:ios:url" content={appLink} />
      <meta
        property="al:ios:app_store_id"
        key="al:ios:app_store_id"
        content={appStoreId}
      />
      <meta
        property="al:ios:app_name"
        key="al:ios:app_name"
        content={appName}
      />
      <meta
        name="apple-itunes-app"
        content={`app-id=${appStoreId}, app-argument=${appLink}`}
      />
    </Head>
  );
};

export default AppLinks;
