import { LanguageContext } from '@/common/context/LanguageContext';
import { getAppStoreUrl, getPlayStoreUrl } from '@/common/utils/externalUrls';
import appPreviewImage from '@/modules/assets/images/app-preview.jpg';
import Image from 'next/image';
import { FC, useContext } from 'react';
import styled from 'styled-components';
import { BodyText, Heading1 } from '../../design/components/textstyles';

const StyledAppBar = styled.section`
  margin: 64px 0;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.mobileMaxWidth}) {
    flex-direction: column-reverse;
  }
`;

const Content = styled.div`
  flex: 1;

  @media (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.mobileMaxWidth}) {
    margin-right: 0;
  }
`;

const AppImage: FC = () => (
  <Image
    src={appPreviewImage}
    alt="Vinted app"
    width={480}
    height={(480 / appPreviewImage.width) * appPreviewImage.height}
  />
);

const StyledBodyText = styled(BodyText)`
  white-space: pre-line;
  margin-top: 24px;
`;

const AppButtons = styled.div`
  display: flex;
  margin-top: 40px;

  a {
    margin-right: 24px;

    &:last-child {
      margin-right: 0;
    }
  }

  img {
    height: 46px;
  }

  @media (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.mobileMaxWidth}) {
    margin-top: 24px;
    justify-content: center;
  }
`;

const AppBar: FC = () => {
  const { translate } = useContext(LanguageContext);
  return (
    <StyledAppBar>
      <Content>
        <Heading1>{translate('home_download_app_title')}</Heading1>
        <StyledBodyText>{translate('home_download_app_expl')}</StyledBodyText>
        <AppButtons>
          <a href={getAppStoreUrl()} rel="noreferrer" target="_blank">
            <img
              alt="App Store"
              src={require('@/modules/assets/icons/app-store-badge.svg')}
            />
          </a>
          <a href={getPlayStoreUrl()} rel="noreferrer" target="_blank">
            <img
              alt="Google Play"
              src={require('@/modules/assets/icons/google-play-badge.svg')}
            />
          </a>
        </AppButtons>
      </Content>
      <Content>
        <AppImage />
      </Content>
    </StyledAppBar>
  );
};

export default AppBar;
