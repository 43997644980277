import Head from 'next/head';
import { useRouter } from 'next/router';
import { FC } from 'react';
import { locales, supportedLanguages } from '../../constants';
import { webUrl } from '../../utils/url';

const AlternateLanguages: FC = () => {
  const router = useRouter();

  return (
    <Head>
      {supportedLanguages.map((language) => {
        return (
          <link
            key={language}
            rel="alternate"
            href={webUrl(language, router.asPath)}
            hrefLang={locales[language].replace('_', '-')}
          />
        );
      })}
    </Head>
  );
};

export default AlternateLanguages;
