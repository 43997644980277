import UserLink from '@/common/components/UserLink';
import { LanguageContext } from '@/common/context/LanguageContext';
import { usePopularSellersQuery } from '@/common/generated/graphql';
import { formatNumber } from '@/common/utils/number';
import { FC, useContext } from 'react';
import styled from 'styled-components';
import FollowButton from '../../design/components/buttons/FollowButton';
import Spinner from '../../design/components/spinner/Spinner';
import {
  BodyText,
  Heading2,
  Heading5,
} from '../../design/components/textstyles';

export const RECOMMENDED_SELLERS_FOLLOWING_USER_ID = '234189';

const StyledSellersBar = styled.section`
  margin: 112px 0;
`;

const Title = styled(Heading2)`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  margin-bottom: 32px;
`;

const UsersContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 32px;

  @media (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.tabletMaxWidth}) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.mobileMaxWidth}) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const ProfileImageContainer = styled.div`
  width: 100%;
  padding-top: 100%;
  position: relative;
`;

const ProfileImage = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
`;

const DisplayName = styled(Heading5)`
  display: block;
  margin-top: 16px;
  text-align: center;
`;

const FollowerCount = styled(BodyText)`
  margin-top: 2px;
  text-align: center;
`;

const StyledFollowButton = styled(FollowButton)`
  margin: 16px auto 0 auto;
  height: 38px;
  padding: 0 8px;
  min-width: 120px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: ${({ theme, viewerIsFollowing }) =>
    viewerIsFollowing ? 'none' : `1px solid ${theme.colors.CalypsoCP1}`};
  appearance: none;
  border-radius: 4px;
  background-color: ${({ theme, viewerIsFollowing }) =>
    viewerIsFollowing ? theme.colors.CalypsoCP1 : theme.colors.CG7};
  color: ${({ theme, viewerIsFollowing }) =>
    viewerIsFollowing ? theme.colors.CG7 : theme.colors.CalypsoCP1};
  outline: none;
  font-size: 1.4rem;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  text-align: center;
  cursor: pointer;
`;

const SellersBar: FC = () => {
  const { translate, locale } = useContext(LanguageContext);

  const { data, loading } = usePopularSellersQuery({
    variables: { userId: RECOMMENDED_SELLERS_FOLLOWING_USER_ID },
  });

  return (
    <StyledSellersBar>
      <Title>{translate('home_sellers_title')}</Title>
      <UsersContainer>
        {loading || !data ? (
          <Spinner wait={100} />
        ) : (
          data.user?.followingConnection?.nodes?.map((user) => (
            <div key={user.id}>
              <ProfileImageContainer>
                <UserLink userId={user.id} passHref>
                  <a>
                    <ProfileImage
                      src={user.profileImage.url}
                      alt={user.displayName}
                    />
                  </a>
                </UserLink>
              </ProfileImageContainer>
              <UserLink userId={user.id} passHref>
                <DisplayName as={'a'}>{user.displayName}</DisplayName>
              </UserLink>
              <FollowerCount>
                {user.followerCount === 1
                  ? translate('gen_one_follower')
                  : translate(
                      'gen_n_followers',
                      formatNumber(user.followerCount, {
                        locale,
                        abbreviate: true,
                      })
                    )}
              </FollowerCount>
              <StyledFollowButton
                userId={user.id}
                viewerIsFollowing={user.viewerIsFollowing}
                entryPoint={'Home'}
              >
                {translate(
                  user.viewerIsFollowing ? 'gen_following' : 'gen_follow'
                )}
              </StyledFollowButton>
            </div>
          ))
        )}
      </UsersContainer>
    </StyledSellersBar>
  );
};

export default SellersBar;
