import HorizontalProductScroller from '@/common/components/HorizontalProductScroller';
import { LanguageContext } from '@/common/context/LanguageContext';
import { PopularItemsComponent } from '@/common/generated/graphql';
import { buildAs, buildHref } from '@/common/utils/filters';
import Link from 'next/link';
import { FC, useContext } from 'react';
import styled from 'styled-components';
import Spinner from '../../design/components/spinner/Spinner';
import { Heading2, UserInputText } from '../../design/components/textstyles';

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 32px;
`;

const Title = styled(Heading2)`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

const SeeAll = styled(UserInputText).attrs({ as: 'a' })`
  text-decoration: underline;
`;

const StyledPopularItems = styled.section`
  margin: 64px 0;
`;

const PopularItems: FC = () => {
  const { translate } = useContext(LanguageContext);

  return (
    <StyledPopularItems>
      <TitleContainer>
        <Title>{translate('home_popular_items')}</Title>
        <Link href={buildHref({})} as={buildAs({}, { path: '/feed' })} passHref>
          <SeeAll>{translate('gen_see_all')}</SeeAll>
        </Link>
      </TitleContainer>
      <PopularItemsComponent>
        {({ data, loading }) => {
          if (loading) {
            return <Spinner wait={100} />;
          }
          if (!data) {
            return null;
          }
          return (
            <HorizontalProductScroller
              products={data.productsConnection.nodes}
              entryPoint="Home"
            />
          );
        }}
      </PopularItemsComponent>
    </StyledPopularItems>
  );
};

export default PopularItems;
