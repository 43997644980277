import { AuthContext } from '@/common/context/AuthContext';
import { LanguageContext } from '@/common/context/LanguageContext';
import buyingStep1Image from '@/modules/assets/images/home-buying-step-1.jpg';
import buyingStep2Image from '@/modules/assets/images/home-buying-step-2.jpg';
import buyingStep3Image from '@/modules/assets/images/home-buying-step-3.jpg';
import sellingStep1Image from '@/modules/assets/images/home-selling-step-1.jpg';
import sellingStep2Image from '@/modules/assets/images/home-selling-step-2.jpg';
import sellingStep3Image from '@/modules/assets/images/home-selling-step-3.jpg';
import Image, { StaticImageData } from 'next/image';
import { FC, PropsWithChildren, useContext } from 'react';
import styled from 'styled-components';
import AuthLink from '../../auth/components/AuthLink';
import SecondaryButton from '../../design/components/buttons/SecondaryButton';
import {
  BodyText,
  Heading1,
  Heading2,
} from '../../design/components/textstyles';
import { FilterLink } from '../../feed/components/ProductFilters/FilterLink';

const StyledStepsBar = styled.section`
  margin: 112px 0;
  display: flex;
  flex-direction: column;
`;

const Title = styled(Heading1)`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

const Steps = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px;
  margin-top: 60px;

  @media (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.mobileMaxWidth}) {
    grid-template-columns: 1fr;
  }
`;

const StepTitle = styled(Heading2)`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  margin-top: 24px;
`;

const StepBody = styled(BodyText)`
  margin-top: 16px;
`;

const StyledButton = styled(SecondaryButton)`
  border: 1px solid ${({ theme }) => theme.colors.CalypsoCP1};
  align-self: center;
  margin-top: 64px;
  min-width: 0;
`;

export const SellingBar: FC = () => {
  const { translate } = useContext(LanguageContext);
  const { isAuthorized } = useContext(AuthContext);
  return (
    <StepsBar
      title={translate('home_selling_title')}
      step={[
        {
          image: sellingStep1Image,
          title: translate('home_selling_1_title'),
          body: translate('home_selling_1_expl'),
        },
        {
          image: sellingStep2Image,
          title: translate('home_selling_2_title'),
          body: translate('home_selling_2_expl'),
        },
        {
          image: sellingStep3Image,
          title: translate('home_selling_3_title'),
          body: translate('home_selling_3_expl'),
        },
      ]}
    >
      <AuthLink
        isAuthorized={isAuthorized}
        href={'/add-product'}
        passHref={true}
      >
        <StyledButton as={'a'}>{translate('gen_start_selling')}</StyledButton>
      </AuthLink>
    </StepsBar>
  );
};

export const BuyingBar: FC = () => {
  const { translate } = useContext(LanguageContext);
  return (
    <StepsBar
      title={translate('home_buying_title')}
      step={[
        {
          image: buyingStep1Image,
          title: translate('home_buying_1_title'),
          body: translate('home_buying_1_expl'),
        },
        {
          image: buyingStep2Image,
          title: translate('home_buying_2_title'),
          body: translate('home_buying_2_expl'),
        },
        {
          image: buyingStep3Image,
          title: translate('home_buying_3_title'),
          body: translate('home_buying_3_expl'),
        },
      ]}
    >
      <FilterLink filters={{}} passHref={true}>
        <StyledButton as={'a'}>{translate('gen_start_buying')}</StyledButton>
      </FilterLink>
    </StepsBar>
  );
};

type Props = {
  title: string;
  step: { image: StaticImageData; title: string; body: string }[];
} & PropsWithChildren;

const StepsBar: FC<Props> = ({ title, step, children }) => {
  return (
    <StyledStepsBar>
      <Title>{title}</Title>
      <Steps>
        {step.map((step, i) => (
          <div key={i}>
            <Image
              width={360}
              height={(360 / step.image.width) * step.image.height}
              src={step.image}
              alt={step.title}
            />
            <StepTitle>{step.title}</StepTitle>
            <StepBody>{step.body}</StepBody>
          </div>
        ))}
      </Steps>
      {children}
    </StyledStepsBar>
  );
};
