import ResponsiveContainer from '@/common/components/ResponsiveContainer';
import { AuthContext } from '@/common/context/AuthContext';
import { EventsContext } from '@/common/context/EventsContext';
import { ExperimentContext } from '@/common/context/ExperimentContext';
import { LanguageContext } from '@/common/context/LanguageContext';
import { getClosureUrl } from '@/common/utils/url';
import homeHeaderOverlayImage from '@/modules/assets/images/home-header-overlay.png';
import homeHeaderImage from '@/modules/assets/images/home-header.jpg';
import Image from 'next/image';
import { FC, useContext } from 'react';
import styled from 'styled-components';
import AuthLink from '../../auth/components/AuthLink';
import PrimaryButton from '../../design/components/buttons/PrimaryButton';
import { BodyText, Heading1 } from '../../design/components/textstyles';

const StyledHeaderBar = styled(ResponsiveContainer)`
  position: relative;
  min-height: 520px;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileMaxWidth}) {
    min-height: 245px;
    padding: 0;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: -1px;
    right: 0;
    height: 64px;
    width: 100%;
    background-position: bottom right;
    background-size: contain;
    background-repeat: no-repeat;
  }
`;

const MainHeaderImageContainer = styled.div`
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileMaxWidth}) {
    position: relative;
  }
`;

const HeaderImageContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileMaxWidth}) {
    position: relative;
    transform: none;
  }
`;

const OverlayContainer = styled.div`
  position: absolute;
  bottom: -1px;
  right: 0;
  line-height: 0;
`;

const HeaderImage: FC = () => (
  <MainHeaderImageContainer>
    <HeaderImageContainer>
      <Image
        className="headerImage"
        src={homeHeaderImage}
        layout="responsive"
        priority={true}
      />
    </HeaderImageContainer>
    <OverlayContainer>
      <Image
        className="overlay"
        height={64}
        width={
          (64 / homeHeaderOverlayImage.height) * homeHeaderOverlayImage.width
        }
        src={homeHeaderOverlayImage}
      />
    </OverlayContainer>
  </MainHeaderImageContainer>
);

const ContentContainer = styled.div`
  width: 420px;
  background-color: ${({ theme }) => theme.colors.CG7};
  border-radius: 4px;
  padding: 32px 32px 40px 32px;
  margin: 32px 0;
  box-shadow: 0 2px 4px 0 rgba(17, 17, 17, 0.2);

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileMaxWidth}) {
    width: 100%;
    box-shadow: none;
    padding: 40px 20px 0 20px;
  }
`;

const Title = styled(Heading1)`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

const Button = styled(PrimaryButton).attrs({ as: 'a' })`
  min-width: 0;
  margin-top: 32px;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileMaxWidth}) {
    width: 100%;
  }
`;

const StyledBodyText = styled(BodyText)`
  margin-top: 8px;
`;

const LearnMoreButton = styled(PrimaryButton)`
  max-width: none;
  width: 100%;
  margin-top: 32px;
`;

const HeaderBar: FC = () => {
  const { translate, language } = useContext(LanguageContext);
  const { isAuthorized } = useContext(AuthContext);
  const { preComms } = useContext(ExperimentContext);
  const { logEvent } = useContext(EventsContext);

  const handleOnLearnMoreClick = () => {
    logEvent('info_closure_banner');
  };

  if (preComms.isInVariant) {
    return (
      <StyledHeaderBar>
        <HeaderImage />
        <ContentContainer>
          <Title>{translate('closure_welcome_title')}</Title>
          <StyledBodyText>{translate('closure_welcome_expl')}</StyledBodyText>
          <LearnMoreButton
            as="a"
            target="_blank"
            href={getClosureUrl(language)}
            onClick={handleOnLearnMoreClick}
          >
            {translate('closure_learn_more_cta')}
          </LearnMoreButton>
        </ContentContainer>
      </StyledHeaderBar>
    );
  }

  return (
    <StyledHeaderBar>
      <HeaderImage />
      <ContentContainer>
        <Title>{translate('home_header_title_2')}</Title>
        <AuthLink isAuthorized={isAuthorized} href="/add-product" passHref>
          <Button>{translate('gen_start_selling')}</Button>
        </AuthLink>
      </ContentContainer>
    </StyledHeaderBar>
  );
};

export default HeaderBar;
